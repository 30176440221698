@charset 'UTF-8';

.gl-footer {
  padding-top: 40px;
  padding-bottom: 20px;
  @include sp {
    padding: 20px 15px 90px;
  }
  &--banner {
    display: block;
    width: 480px;
    margin: 0 auto 32px;
    @include sp {
      width: 100%;
      margin-bottom: 24px;
    }
    &:hover {
      .gl-footer--banner_pic {
        opacity: 0.8;
      }
    }
  }
  &--banner_pic {
    transition: .15s;
  }
  &--copy {
    font-size: 12px;
    @include sp {
      margin-top: 15px;
    }
  }
  &--txt {
    font-size: 10px;
    line-height: 1.5;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 10px;
    a {
      color: $color-main;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.gl-footer_contents {
  max-width: 950px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  @include sp {
    flex-wrap: wrap;
  }
}

.gl-footer_list {
  @include sp {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
  &--item {
    display: inline-block;
    margin-right: 40px;
    @include sp {
      width: 50%;
      margin-right: 0;
    }
    &:nth-child(n+3) {
      @include sp {
        margin-top: 8px;
      }
    }
  }
  &--link {
    display: block;
    font-size: 12px;
    line-height: 1.5;
    position: relative;
    transition: .15s;
    &:before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: relative;
      top: 5px;
      background: $color-main;
    }
    &:after {
      content: '';
      display: inline-block;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      transform: rotate(-135deg);
      position: absolute;
      top: calc(50% - 1px);
      left: 5px;
      width: 6px;
      height: 6px;
    }
    &:hover {
      color: $color-main;
    }
  }
}
