@charset "UTF-8";
/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
.gl-header {
  width: 100%;
  padding: 15px;
  background: #fff;
  border-bottom: 5px solid #00528c;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

@media screen and (max-width: 767px) {
  .gl-header {
    height: 70px;
    padding: 15px 15px 15px 10px;
  }
}

.gl-header--inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.gl-header--logo {
  display: block;
  position: relative;
}

@media screen and (max-width: 767px) {
  .gl-header--logo {
    margin-left: 0;
    width: 244px;
    top: -4px;
  }
}

.gl-header--logo_img {
  display: block;
  max-width: 100%;
  height: auto;
}

.gl-header--hamburger {
  display: none;
}

@media screen and (max-width: 767px) {
  .gl-header--hamburger {
    background-color: #3AA4AF;
    width: 65px;
    position: absolute;
    right: 0;
    top: 0;
    color: #fff;
    font-size: 10px;
    height: 65px;
    text-align: center;
    display: block;
  }
}

.gl-header--hamburger.active {
  overflow: hidden;
}

.gl-header--hamburger.active .gl-header--hamburger_border:nth-child(1) {
  transform: rotate(45deg);
  top: 24px;
}

.gl-header--hamburger.active .gl-header--hamburger_border:nth-child(2) {
  left: -100%;
}

.gl-header--hamburger.active .gl-header--hamburger_border:nth-child(3) {
  transform: rotate(-45deg);
  top: 24px;
}

.gl-header--hamburger_border {
  border-bottom: 2px solid #fff;
  position: absolute;
  height: 2px;
  width: 30px;
  left: calc(50% - 15px);
  display: block;
  transition: 0.15s;
}

.gl-header--hamburger_border:nth-child(1) {
  top: 15px;
}

.gl-header--hamburger_border:nth-child(2) {
  top: 24px;
}

.gl-header--hamburger_border:nth-child(3) {
  top: 33px;
}

.gl-header--hamburger_txt {
  width: 100%;
  position: absolute;
  bottom: 12px;
  left: 0;
}

.gl-header_nav {
  display: flex;
  flex-wrap: wrap;
  max-width: 460px;
  position: relative;
  padding: 0 120px 0 0;
  margin: 0 0 0 auto;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .gl-header_nav {
    max-width: 100%;
    height: auto;
    background-color: rgba(255, 255, 255, 0.8);
    min-height: 100vh;
    position: absolute;
    left: 0;
    top: 65px;
    padding: 25px 15px;
    display: none;
    width: 100%;
    opacity: 0;
    transition: 0.15s;
    z-index: 10;
  }
}

.gl-header_nav.show {
  opacity: 1;
}

.gl-header_nav.show .gl-header_nav--link {
  opacity: 1;
  top: 0;
}

.gl-header_nav.show .gl-header_nav--link:nth-child(1) {
  transition: 0.2s;
  transition-delay: 0.03s;
}

.gl-header_nav.show .gl-header_nav--link:nth-child(2) {
  transition: 0.2s;
  transition-delay: 0.06s;
}

.gl-header_nav.show .gl-header_nav--link:nth-child(3) {
  transition: 0.2s;
  transition-delay: 0.09s;
}

.gl-header_nav.show .gl-header_nav--link:nth-child(4) {
  transition: 0.2s;
  transition-delay: 0.12s;
}

.gl-header_nav.show .gl-header_nav--btn {
  opacity: 1;
  top: 0;
  transition: 0.2s;
  transition-delay: 0.12s;
}

.gl-header_nav--link {
  display: block;
  text-decoration: none;
  color: #000;
  font-size: 12px;
  width: 50%;
  position: relative;
  padding: 0 0 0 20px;
  line-height: 15px;
  height: 15px;
  transition: 0.15s;
}

.gl-header_nav--link:before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #3AA4AF;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
}

.gl-header_nav--link:after {
  content: '';
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  width: 4px;
  height: 4px;
  display: block;
  position: absolute;
  left: 4px;
  top: 5px;
  transform: rotate(-135deg);
}

.gl-header_nav--link:hover {
  color: #3AA4AF;
}

.gl-header_nav--link:nth-child(1), .gl-header_nav--link:nth-child(2) {
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .gl-header_nav--link {
    width: 100%;
    background-color: #3AA4AF;
    color: #fff;
    height: 43px;
    line-height: 43px;
    font-size: 14px;
    margin: 0 0 10px 0;
    padding: 0 0 0 30px;
    opacity: 0;
    top: 5px;
  }
  .gl-header_nav--link:after {
    width: 6px;
    height: 6px;
    border-left-width: 2px;
    border-bottom-width: 2px;
    top: calc(50% - 6px);
    left: 10px;
  }
  .gl-header_nav--link:hover {
    color: #fff;
  }
  .gl-header_nav--link:last-child {
    margin-bottom: 0;
  }
  .gl-header_nav--link-margin_bottom {
    margin-bottom: 30px;
  }
}

.gl-header_nav--btn {
  background-color: #3AA4AF;
  color: #fff;
  font-weight: bold;
  width: 120px;
  height: 40px;
  line-height: 40px;
  border-radius: 50px;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.15s;
}

.gl-header_nav--btn:hover {
  background-color: #2d8089;
}

.gl-header_nav--btn-pdf {
  display: none;
}

@media screen and (max-width: 767px) {
  .gl-header_nav--btn {
    display: block;
    position: static;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 21px;
    background-color: #008da8;
    opacity: 0;
    top: 5px;
  }
  .gl-header_nav--btn:last-child {
    margin-bottom: 0;
  }
  .gl-header_nav--btn-pdf {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gl-header_nav--btn-pdf:after {
    content: '';
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2215%22%3E%3Cpath%20data-name%3D%22Icon%20material-insert-drive-file%22%20d%3D%22M1.5%200A1.5%201.5%200%2000.008%201.5L0%2013.5A1.5%201.5%200%20001.492%2015H10.5a1.5%201.5%200%20001.5-1.5v-9L7.5%200zm5.25%205.25V1.125l4.125%204.125z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E");
    display: block;
    width: 12px;
    height: 15px;
    margin-left: 6px;
  }
}

.gl-footer {
  padding-top: 40px;
  padding-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .gl-footer {
    padding: 20px 15px 90px;
  }
}

.gl-footer--banner {
  display: block;
  width: 480px;
  margin: 0 auto 32px;
}

@media screen and (max-width: 767px) {
  .gl-footer--banner {
    width: 100%;
    margin-bottom: 24px;
  }
}

.gl-footer--banner:hover .gl-footer--banner_pic {
  opacity: 0.8;
}

.gl-footer--banner_pic {
  transition: .15s;
}

.gl-footer--copy {
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .gl-footer--copy {
    margin-top: 15px;
  }
}

.gl-footer--txt {
  font-size: 10px;
  line-height: 1.5;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 10px;
}

.gl-footer--txt a {
  color: #3AA4AF;
}

.gl-footer--txt a:hover {
  text-decoration: underline;
}

.gl-footer_contents {
  max-width: 950px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .gl-footer_contents {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .gl-footer_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
}

.gl-footer_list--item {
  display: inline-block;
  margin-right: 40px;
}

@media screen and (max-width: 767px) {
  .gl-footer_list--item {
    width: 50%;
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .gl-footer_list--item:nth-child(n+3) {
    margin-top: 8px;
  }
}

.gl-footer_list--link {
  display: block;
  font-size: 12px;
  line-height: 1.5;
  position: relative;
  transition: .15s;
}

.gl-footer_list--link:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  top: 5px;
  background: #3AA4AF;
}

.gl-footer_list--link:after {
  content: '';
  display: inline-block;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-135deg);
  position: absolute;
  top: calc(50% - 1px);
  left: 5px;
  width: 6px;
  height: 6px;
}

.gl-footer_list--link:hover {
  color: #3AA4AF;
}

.c-briefing {
  color: #fff;
  text-decoration: none;
  position: fixed;
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  z-index: 2;
}

.c-briefing:hover {
  background-color: #000;
  border-color: #000;
}

.c-briefing-pc {
  border: 2px solid #00528c;
  top: 104px;
  right: calc(50% - 490px);
  max-width: 300px;
  background-color: #00528c;
  height: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  transition: background-color .15s, top .2s, left .2s;
}

@media screen and (max-width: 767px) {
  .c-briefing-pc {
    display: none;
  }
}

.c-briefing-personal {
  max-width: 300px;
}

.c-briefing-sp {
  display: none;
}

@media screen and (max-width: 767px) {
  .c-briefing-sp {
    display: block;
    position: fixed;
    width: 100%;
    padding: 10px;
    font-size: 18px;
    text-align: center;
    margin-bottom: 0;
    background-color: #28b5c1;
    border: 0;
    top: initial;
    left: 0;
    bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .c-briefing-sp:before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: calc(50% - 8px);
    right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .c-briefing-sp:after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-right: 2px solid #3AA4AF;
    border-bottom: 2px solid #3AA4AF;
    transform: rotate(-45deg);
    position: absolute;
    top: calc(50% - 3px);
    right: 26px;
  }
}

@media screen and (max-width: 767px) {
  .c-briefing-sp:hover {
    background-color: #2d8089;
  }
}

.c-briefing--txt {
  text-align: center;
  flex: 1;
  position: relative;
  padding: 0 18px 0 0;
}

.c-briefing--txt:before {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: calc(50% - 7px);
}

.c-briefing--txt:after {
  content: '';
  display: block;
  border-left: 1px solid #00528c;
  border-bottom: 1px solid #00528c;
  transform: rotate(-135deg);
  position: absolute;
  right: 16px;
  top: calc(50% - 2px);
  width: 4px;
  height: 4px;
}

@media screen and (max-width: 767px) {
  .c-briefing--txt {
    text-align: left;
    font-size: 14px;
    line-height: 1.5;
    padding: 0 0 0 10px;
    width: 100%;
    display: block;
    box-sizing: border-box;
    flex: inherit;
  }
}

.c-briefing--day {
  background-color: #fff;
  color: #000;
  height: 100%;
  display: block;
  padding: 0 10px;
  line-height: 36px;
}

@media screen and (max-width: 767px) {
  .c-briefing--day {
    flex: 1;
    font-size: 13px;
    line-height: 45px;
    padding: 0 10px;
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .c-briefing.fixed {
    width: 70%;
    position: fixed;
    top: initial;
    left: 15px;
    bottom: 25px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .c-briefing--micro {
    font-size: 13px;
    display: block;
    margin-bottom: 3px;
  }
}

.c-course {
  max-width: 332px;
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  line-height: 1;
  text-decoration: none;
  border-bottom: 5px solid transparent;
  position: relative;
  transition: 0.15s;
}

@media screen and (max-width: 767px) {
  .c-course {
    max-width: 100%;
    margin-bottom: 10px;
  }
  .c-course:hover {
    transform: scale(1, 1);
  }
  .c-course:last-child {
    margin-bottom: 0;
  }
}

.c-course-green {
  left: 0;
  border-bottom-color: #39A06E;
}

.c-course-green:hover {
  border-bottom-color: #2c7a54;
}

.c-course-green:hover .c-course--ttl {
  background-color: #2c7a54;
}

.c-course-green:hover .c-course--ttl:before {
  border-color: #2c7a54 transparent transparent transparent;
}

.c-course-green:hover .c-course--edge {
  background-color: #2c7a54;
}

.c-course-green:hover .c-course--edge:before {
  border-color: transparent transparent #2c7a54 transparent;
}

.c-course-green .c-course--ttl {
  background-color: #39A06E;
}

.c-course-green .c-course--ttl:before {
  border-top-color: #39A06E;
}

.c-course-green .c-course--catch {
  color: #39A06E;
}

.c-course-green .c-course--txt {
  color: #39A06E;
}

.c-course-green .c-course--edge {
  background-color: #39A06E;
}

.c-course-green .c-course--edge:before {
  border-bottom-color: #39A06E;
}

.c-course-green .c-course--arrow:before {
  border-bottom-color: #39A06E;
  border-left-color: #39A06E;
}

.c-course-orange {
  right: 0;
  border-bottom-color: #e28a35;
}

.c-course-orange:hover {
  border-bottom-color: #c7711d;
}

.c-course-orange:hover .c-course--ttl {
  background-color: #c7711d;
}

.c-course-orange:hover .c-course--ttl:before {
  border-color: #c7711d transparent transparent transparent;
}

.c-course-orange:hover .c-course--edge {
  background-color: #c7711d;
}

.c-course-orange:hover .c-course--edge:before {
  border-color: transparent transparent #c7711d transparent;
}

.c-course-orange .c-course--ttl {
  background-color: #e28a35;
}

.c-course-orange .c-course--ttl:before {
  border-top-color: #e28a35;
}

.c-course-orange .c-course--catch {
  color: #e28a35;
}

.c-course-orange .c-course--txt {
  color: #e28a35;
}

.c-course-orange .c-course--edge {
  background-color: #e28a35;
}

.c-course-orange .c-course--edge:before {
  border-bottom-color: #e28a35;
}

.c-course-orange .c-course--arrow:before {
  border-bottom-color: #e28a35;
  border-left-color: #e28a35;
}

.c-course--ttl {
  background-color: red;
  color: #fff;
  position: relative;
  height: 28px;
  line-height: 28px;
  width: 130px;
  text-align: center;
  display: block;
  margin: 0 0 10px 0;
  transition: 0.15s;
}

.c-course--ttl:before {
  content: "";
  display: block;
  position: absolute;
  right: -20px;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 28px 20px 0 0;
  border-color: red transparent transparent transparent;
  transition: 0.15s;
}

@media screen and (max-width: 767px) {
  .c-course--ttl {
    margin-bottom: 8px;
  }
}

.c-course--catch {
  font-style: italic;
  font-weight: bold;
  position: absolute;
  left: 150px;
  top: 8px;
  font-size: 12px;
}

.c-course--day {
  font-weight: bold;
  color: #000;
  font-size: 22px;
  text-align: center;
  margin: 0 0 15px 0;
}

@media screen and (max-width: 767px) {
  .c-course--day {
    text-align: left;
    font-size: 14px;
    padding: 0 10px;
    letter-spacing: -1px;
    margin-bottom: 6px;
  }
}

.c-course--txt {
  font-size: 16px;
  text-align: right;
  padding: 0 45px 8px 0;
  display: block;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .c-course--txt {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 12px;
  }
}

.c-course--edge {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
  background-color: red;
  transition: 0.15s;
}

.c-course--edge:before {
  content: '';
  display: block;
  position: absolute;
  left: -12px;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 30px 12px;
  border-color: transparent transparent red transparent;
  transition: 0.15s;
}

@media screen and (max-width: 767px) {
  .c-course--edge {
    height: 25px;
    width: 25px;
  }
  .c-course--edge:before {
    border-width: 0 0 25px 10px;
    left: -10px;
  }
}

.c-course--arrow {
  background-color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  right: 9px;
  top: 9px;
  display: block;
}

.c-course--arrow:before {
  content: '';
  display: block;
  border-left: 1px solid #00528c;
  border-bottom: 1px solid #00528c;
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
  position: absolute;
  top: 5px;
  right: 6px;
  width: 4px;
  height: 4px;
}

@media screen and (max-width: 767px) {
  .c-course--arrow {
    right: 6px;
    top: 6px;
  }
}

.gl_navi {
  float: right;
  padding: 13px 130px 0 0;
  list-style: none;
  width: 520px;
  position: relative;
  font-size: 14px;
  font-size: 0.875rem;
}

.gl_navi--item {
  position: relative;
  top: 12px;
  float: left;
  margin-right: 10px;
  margin: 0 0 7px 10px;
  width: 180px;
}

.gl_navi--item_link {
  transition: .15s;
  text-decoration: none;
  color: #000;
}

.gl_navi--item_link:before {
  position: relative;
  top: 2px;
  display: inline-block;
  margin-right: 5px;
  content: '';
  background-image: url(/common/images/sprite.png);
  background-position: -60px -28px;
  width: 15px;
  height: 15px;
  background-size: 110px 70px;
}

.gl_navi--item_link:hover {
  opacity: .8;
}

.gl_navi--contact {
  position: absolute;
  right: 0;
  top: 8px;
}

.gl_navi--contact .gl_navi--item_link {
  display: inline-block;
  width: 100px;
  padding: 5px 0;
  line-height: 1.4;
  position: relative;
  width: 130px;
  top: 4px;
  transition: .15s;
  text-align: center;
  color: #fff;
  border-radius: 3px;
  background-color: #008da8;
  font-size: 12px;
  font-size: 0.75rem;
}

.gl_navi--contact .gl_navi--item_link:before {
  display: none;
}

.gl_navi--contact .gl_navi--item_link:hover {
  background-color: #3AA4AF;
}

.gl_navi--big {
  font-size: 14px;
  font-size: 0.875rem;
  display: block;
  margin: 2px 0 0 0;
}

@media only screen and (max-width: 600px) {
  .gl_navi {
    position: absolute;
    top: 50px;
    left: 0;
    display: none;
    width: 100%;
    padding-top: 10px;
    padding-right: 0;
    padding-left: 0;
  }
  .gl_navi--big {
    display: inline-block;
  }
  .gl_navi--item {
    top: 0;
    float: none;
    width: 100%;
    margin-bottom: 10px;
    padding: 0 10px;
    margin-left: 0;
  }
  .gl_navi--item_link {
    line-height: 43px;
    display: block;
    float: none;
    width: 100%;
    height: 43px;
    text-align: center;
    color: #fff;
    border-radius: 3px;
    background-color: #3AA4AF;
  }
  .gl_navi--contact {
    float: none;
    margin-bottom: 15px;
    margin-top: 20px;
    height: auto;
    top: 0;
    width: 100%;
    margin-left: 0;
    padding: 0 10px 15px;
    position: static;
  }
  .gl_navi--contact .gl_navi--item_link {
    display: block;
    height: auto;
    width: 100%;
    top: 0;
    padding: 10px 0;
  }
}

.humburger {
  position: absolute;
  top: 50%;
  right: 10px;
  display: none;
  width: 27px;
  height: 25px;
  margin-top: -10px;
}

@media only screen and (max-width: 600px) {
  .humburger {
    display: block;
  }
  .humburger--bg {
    display: none;
  }
}

.humburger--line {
  display: block;
  width: 100%;
  margin-bottom: 6px;
  margin-left: auto;
  transition: .3s;
  border-bottom: 4px solid #3AA4AF;
}

.humburger--line:nth-child(2) {
  width: 60%;
}

.humburger--line:last-child {
  width: 80%;
}

.humburger--bg {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: block;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}

.humburger-on .humburger--line {
  position: relative;
}

.humburger-on .humburger--line:first-child {
  top: 7px;
  transform: rotate(-45deg);
}

.humburger-on .humburger--line:nth-child(2) {
  opacity: 0;
}

.humburger-on .humburger--line:last-child {
  top: -13px;
  width: 100%;
  transform: rotate(45deg);
}

.footer {
  height: 57px;
  margin: 0 auto;
  padding: 20px 0;
  background-color: #e6e6e6;
  font-size: 12px;
  font-size: 0.75rem;
}

.footer:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '.';
}

.footer--inner {
  max-width: 950px;
  margin: 0 auto;
}

.footer--pp {
  display: block;
  float: left;
  width: 300px;
  text-decoration: none;
  color: #000;
  transition: 0.15s;
  position: relative;
}

.footer--pp:hover {
  color: #3AA4AF;
}

.footer--pp:before {
  content: '';
  display: inline-block;
  background-image: url(/common/images/sprite.png);
  background-position: -60px -28px;
  width: 15px;
  height: 15px;
  background-size: 110px 70px;
  position: relative;
  left: 0;
  top: 3px;
  margin-right: 5px;
}

.footer--copyright {
  float: right;
  vertical-align: middle;
  text-align: right;
  position: relative;
  top: -3px;
}

.footer--copyright_text {
  font-size: 10px;
  font-size: 0.625rem;
  display: block;
  margin-top: 5px;
}

.footer--link {
  color: #3AA4AF;
}

@media only screen and (max-width: 600px) {
  .footer {
    height: auto;
    padding-bottom: 20px;
    text-align: center;
    font-size: 11px;
    font-size: 0.6875rem;
  }
  .footer--pp {
    float: none;
    width: 100%;
    margin-bottom: 10px;
    margin-left: 0;
  }
  .footer--copyright {
    float: none;
    width: 100%;
  }
  .footer--copyright_text {
    line-height: 1.6;
    padding: 0 10px;
    text-align: center;
  }
}

.contact {
  padding: 50px 0;
  background-color: #3AA4AF;
}

.contact--button {
  font-weight: bold;
  position: relative;
  z-index: 10;
  display: block;
  width: 100%;
  max-width: 575px;
  margin: 0 auto;
  padding: 20px 0 15px;
  transition: .2s;
  text-align: center;
  text-decoration: none;
  color: #3AA4AF;
  box-shadow: 4px 4px 0 #008da8;
  font-size: 26px;
  font-size: 1.625rem;
}

.contact--button:before {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  transition: .2s;
  background-color: #fff;
}

.contact--button:hover {
  box-shadow: 0 0 3px #008da8;
}

.contact--button:hover:before {
  background-color: #008da8;
}

@media only screen and (max-width: 600px) {
  .contact {
    padding: 20px 10px;
  }
  .contact--button {
    padding: 15px 15px;
    font-size: 16px;
    font-size: 1rem;
  }
}

.c-access {
  padding-top: 30px;
}

.c-access--ttl {
  color: #01669C;
  font-size: 30px;
  line-height: 1.5;
  text-align: center;
  margin: 0 0 40px 0;
}

@media screen and (max-width: 767px) {
  .c-access--ttl {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 1.3;
  }
}

.c-access_box {
  display: flex;
}

@media screen and (max-width: 767px) {
  .c-access_box {
    flex-wrap: wrap;
  }
}

.c-access_box--detail {
  width: 500px;
  padding: 20px 20px 40px 0;
  margin-left: calc(50vw - 500px);
}

@media screen and (max-width: 767px) {
  .c-access_box--detail {
    width: 100%;
    margin-left: 0;
    order: 1;
    padding: 20px 15px;
  }
}

.c-access_box--wrap {
  margin: 0 0 16px;
}

.c-access_box--wrap:after {
  content: '';
  width: 94%;
  height: 1px;
  background: #01669B;
  display: block;
  margin: 16px 0 0 16px;
}

@media screen and (max-width: 767px) {
  .c-access_box--center_logo {
    display: block;
    margin-bottom: 5px;
  }
}

.c-access_box--tel {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 42px;
  display: block;
  margin: 0 0 20px;
  cursor: default;
  text-decoration: none;
  color: #000;
}

@media screen and (max-width: 767px) {
  .c-access_box--tel {
    font-size: 35px;
  }
}

.c-access_box--link {
  font-size: 14px;
  display: inline-block;
  color: #3AA4AF;
  margin: 2px 0 5px 3px;
}

.c-access_box--txt {
  font-size: 14px;
  line-height: 1.7;
  margin-bottom: 5px;
}

.c-access_box--note {
  font-size: 12px;
  line-height: 1.5;
}

.c-access_box--map {
  width: 50vw;
}

@media screen and (max-width: 767px) {
  .c-access_box--map {
    width: 100%;
    order: 0;
  }
}

.c-access_box--map iframe {
  width: 100%;
  height: 100%;
}

.c-access_program {
  border-left: 5px solid #3AA4AF;
  padding: 0 0 0 16px;
  margin: 0 0 0 14px;
}

.c-access_program--wrap {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0 0 10px;
}

.c-access_program--ttl {
  font-size: 22px;
  font-weight: 600;
  color: #00528c;
  margin: 0 8px 0 0;
}

@media screen and (max-width: 767px) {
  .c-access_program--ttl {
    font-size: 16px;
  }
}

.c-access_program--ttl > sup {
  font-size: 60%;
  position: relative;
  top: -5px;
}

.c-access_program--link {
  font-size: 12px;
  display: block;
  background: #DCDCDC;
  border-radius: 10px;
  width: 57px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  transition: .2s;
}

.c-access_program--link:hover {
  background: #999;
}

.c-access_program--text {
  line-height: 1.7;
}

.c-access_program--note {
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .c-access_program--note {
    font-size: 12px;
  }
}

.c-interview {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .c-interview {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.c-interview--ttl {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .c-interview--ttl {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 1.3;
  }
}

.c-interview_list {
  max-width: 780px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .c-interview_list {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.c-interview_list--item:nth-child(n+3) {
  margin: 60px 0 0;
}

@media screen and (max-width: 767px) {
  .c-interview_list--item:nth-child(n+2) {
    margin: 30px 0 0;
  }
}

.c-interview_list--link {
  width: 360px;
  display: block;
}

@media screen and (max-width: 767px) {
  .c-interview_list--link {
    width: 100%;
  }
}

.c-interview_list--link:hover .interview_pagination--button-narrow {
  background: #3aa3ae;
  border: solid 2px #3AA3AE;
  transition: 0.15s;
  color: #ffffff;
}

.c-interview_list--pic {
  display: block;
}

.c-interview_list--pic img {
  display: block;
}

.c-interview_list--name {
  font-size: 24px;
  text-align: center;
  display: block;
  margin-top: 10px;
  margin-bottom: 16px;
}

@media screen and (max-width: 767px) {
  .c-interview_list--name {
    font-size: 16px;
  }
}

.c-interview_list--name-interview {
  color: #3aa3ae;
  font-weight: bold;
}

.c-interview_list--btn {
  width: 175px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  background: #3AA4AF;
  margin: 0 auto;
  display: block;
  padding: 8px;
  position: relative;
  transition: background-color 0.15s;
}

@media screen and (max-width: 767px) {
  .c-interview_list--btn {
    width: 200px;
  }
}

.c-interview_list--btn:after {
  content: '';
  width: 6px;
  height: 6px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
  display: block;
  position: absolute;
  top: calc(50% - 4px);
  right: 10px;
}

.c-interview_list--detail {
  margin-bottom: 24px;
}

@media screen and (max-width: 767px) {
  .c-interview_list--detail {
    margin-top: 15px;
    margin-bottom: 20px;
  }
}

.c-interview_list--detail_item {
  margin-bottom: 8px;
  font-size: 16px;
}

.c-interview_list--detail_item:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .c-interview_list--detail_item {
    margin-bottom: 8px;
  }
}

.c-interview_list--detail_span {
  position: relative;
  background: #3aa3ae;
  color: #fff;
  width: 70px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  margin-right: 16px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.c-interview_list--detail_span:after {
  content: "";
  position: absolute;
  right: -10px;
  top: 0;
  border-right: 0px solid transparent;
  border-left: 10px solid #3aa3ae;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

.c-interview_list--color {
  font-weight: bold;
}

.c-interview_list--color-orange {
  color: #e18935;
}

.c-interview_list--color-green {
  color: #399F6E;
}

.c-support_briefing {
  max-width: 980px;
  margin: 40px auto;
  border: 2px solid #00528c;
  border-radius: 20px;
  padding: 35px 50px;
}

@media screen and (max-width: 767px) {
  .c-support_briefing {
    margin: 30px 15px;
    padding: 20px 15px;
    border-radius: 10px;
  }
}

.c-support_briefing--ttl {
  color: #00528c;
  text-align: center;
  margin: 0 0 20px 0;
  font-size: 30px;
}

@media screen and (max-width: 767px) {
  .c-support_briefing--ttl {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 10px;
  }
}

.c-support_briefing--attention {
  text-align: center;
  margin: 20px 0 0;
  font-size: 14px;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .c-support_briefing--attention {
    font-size: 12px;
    text-align: left;
    margin: 10px 0 0;
  }
}

.c-support_briefing--description {
  text-align: center;
  margin: 0 0 30px 0;
  line-height: 1.8;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .c-support_briefing--description {
    font-size: 14px;
    text-align: left;
    margin-bottom: 15px;
  }
  .c-support_briefing--description br {
    display: none;
  }
}

.c-support_briefing--content {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin: 0 0 50px 0;
  justify-content: space-between;
  align-items: flex-end;
}

@media screen and (max-width: 767px) {
  .c-support_briefing--content {
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-bottom: 30px;
  }
}

.c-support_briefing--headline {
  font-size: 18px;
  line-height: 1.5;
  padding: 0 0 0 55px;
  display: block;
  position: relative;
}

.c-support_briefing--headline:before {
  content: '';
  display: block;
  background: url("/common/images/c-support-briefing-icon.png") no-repeat;
  background-size: cover;
  width: 41px;
  height: 40px;
  position: absolute;
  left: 0;
  top: calc(50% - 20px);
}

@media screen and (max-width: 767px) {
  .c-support_briefing--headline {
    text-align: left;
    width: 200px;
    font-size: 14px;
    display: block;
    margin: 15px auto 0;
  }
  .c-support_briefing--headline:first-child {
    margin-top: 0;
  }
}

@media screen and (max-width: 767px) {
  .c-support_briefing--other {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 14px;
  }
}

.c-support_briefing--btn {
  color: #fff;
  text-decoration: none;
  background-color: #00528c;
  font-size: 22px;
  max-width: 560px;
  width: 100%;
  padding: 32px 0;
  display: block;
  margin: 0 auto;
  text-align: center;
  transition: 0.15s;
}

.c-support_briefing--btn:hover {
  background-color: #003459;
}

@media screen and (max-width: 767px) {
  .c-support_briefing--btn {
    width: 100%;
    font-size: 16px;
    line-height: 1.5;
    padding: 10px 0;
    border-radius: 3px;
  }
}

.c-ft_contents {
  background: #3AA4AF;
  padding: 40px 0;
}

@media screen and (max-width: 767px) {
  .c-ft_contents {
    padding: 20px 15px;
  }
}

.c-ft_course {
  max-width: 910px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c-ft_course_box {
  width: 434px;
  border: 2px solid #fff;
}

@media screen and (max-width: 767px) {
  .c-ft_course_box {
    margin-bottom: 20px;
  }
  .c-ft_course_box:last-child {
    margin-bottom: 0;
  }
}

.c-ft_course_box:hover .c-ft_course_box--heading {
  width: 165px;
}

@media screen and (max-width: 767px) {
  .c-ft_course_box:hover .c-ft_course_box--heading {
    width: 146px;
  }
}

.c-ft_course_box-explore .c-ft_course_box--description:before {
  background: #39A06E;
}

.c-ft_course_box-explore .c-ft_course_box--heading {
  background: #39A06E;
}

.c-ft_course_box-explore .c-ft_course_box--heading:after {
  border-color: #39A06E transparent transparent transparent;
}

.c-ft_course_box-explore .c-ft_course_box--free {
  color: #39A06E;
}

.c-ft_course_box-explore .c-ft_course_box--txt {
  color: #39A06E;
}

.c-ft_course_box-break .c-ft_course_box--description:before {
  background: #e28a35;
}

.c-ft_course_box-break .c-ft_course_box--heading {
  background: #e28a35;
}

.c-ft_course_box-break .c-ft_course_box--heading:after {
  border-color: #e28a35 transparent transparent transparent;
}

.c-ft_course_box-break .c-ft_course_box--free {
  color: #e28a35;
}

.c-ft_course_box-break .c-ft_course_box--txt {
  color: #e28a35;
}

.c-ft_course_box--pic img {
  display: block;
}

.c-ft_course_box--description {
  display: flex;
  background: #fff;
  align-items: center;
  position: relative;
}

.c-ft_course_box--description:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  right: 12px;
}

@media screen and (max-width: 767px) {
  .c-ft_course_box--description:before {
    width: 14px;
    height: 14px;
    top: calc(50% - 7px);
    right: 4px;
  }
}

.c-ft_course_box--description:after {
  content: '';
  display: block;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-135deg);
  position: absolute;
  top: calc(50% - 4px);
  right: 19px;
  width: 6px;
  height: 6px;
}

@media screen and (max-width: 767px) {
  .c-ft_course_box--description:after {
    width: 3px;
    height: 3px;
    top: calc(50% - 2px);
    right: 9px;
  }
}

.c-ft_course_box--heading {
  width: 170px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: #fff;
  padding: 5px 0 5px 10px;
  position: relative;
  transition: .2s;
}

@media screen and (max-width: 767px) {
  .c-ft_course_box--heading {
    width: 146px;
    font-size: 12px;
  }
}

.c-ft_course_box--heading:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 52px 25px 0 0;
  position: absolute;
  top: 0;
  right: -25px;
}

@media screen and (max-width: 767px) {
  .c-ft_course_box--heading:after {
    border-width: 46px 15px 0 0;
    right: -15px;
  }
}

.c-ft_course_box--free {
  font-size: 13px;
  font-weight: 600;
  color: #39A06E;
  display: inline-block;
  padding: 3px 5px;
  background: #fff;
  border-radius: 8px;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .c-ft_course_box--free {
    font-size: 12px;
  }
}

.c-ft_course_box--txt {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  padding-left: 40px;
}

@media screen and (max-width: 767px) {
  .c-ft_course_box--txt {
    font-size: 12px;
    padding-left: 20px;
  }
}

.c-ft_interview_box {
  max-width: 910px;
  margin: 40px auto 0;
  display: flex;
  border: 2px solid #fff;
}

@media screen and (max-width: 767px) {
  .c-ft_interview_box {
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 20px;
  }
}

.c-ft_interview_box:hover .c-ft_interview_box--heading {
  width: 120px;
}

.c-ft_interview_box--pic {
  width: 466px;
}

@media screen and (max-width: 767px) {
  .c-ft_interview_box--pic {
    width: 100%;
  }
}

.c-ft_interview_box--pic img {
  display: block;
}

.c-ft_interview_box--description {
  width: calc(100% - 466px);
  display: flex;
  background: #fff;
  position: relative;
}

@media screen and (max-width: 767px) {
  .c-ft_interview_box--description {
    width: 100%;
  }
}

.c-ft_interview_box--description:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #3AA4AF;
  position: absolute;
  top: calc(50% - 10px);
  right: 20px;
}

.c-ft_interview_box--description:after {
  content: '';
  display: block;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-135deg);
  position: absolute;
  top: calc(50% - 4px);
  right: 27px;
  width: 6px;
  height: 6px;
}

.c-ft_interview_box--heading {
  width: 100px;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  background: #3AA4AF;
  display: flex;
  align-items: center;
  position: relative;
  transition: .15s;
}

@media screen and (max-width: 767px) {
  .c-ft_interview_box--heading {
    width: 120px;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
  }
}

.c-ft_interview_box--heading:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 120px 45px 0 0;
  border-color: #3AA4AF transparent transparent transparent;
  position: absolute;
  top: 0;
  right: -45px;
}

@media screen and (max-width: 767px) {
  .c-ft_interview_box--heading:after {
    border-width: 44px 20px 0 0;
    right: -20px;
  }
}

.c-ft_interview_box--txt {
  font-size: 20px;
  font-weight: 600;
  color: #3AA4AF;
  padding-left: 30px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .c-ft_interview_box--txt {
    font-size: 16px;
    padding-left: 30px;
  }
}

.interview_list--item {
  position: relative;
}

.interview_list--item_num {
  position: absolute;
  top: 6px;
  left: -24px;
}

.interview_list--item_num-corp:before {
  border-bottom-color: #008da8;
}

.interview_list--item_pic {
  margin-bottom: 15px;
}

.interview_list--item_name {
  display: block;
  margin-bottom: 15px;
  text-align: center;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.6;
  height: 2.1em;
}

.interview_list--item_button {
  display: block;
  width: 175px;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .interview_list--item_num {
    top: 2px;
    left: -15px;
  }
  .interview_list--item_pic {
    margin-bottom: 5px;
  }
  .interview_list--item_name {
    font-size: 12px;
    font-size: 0.75rem;
  }
  .interview_list--item_button {
    width: 100%;
  }
  .interview_list--item_button:after {
    display: none;
  }
}

.overlay {
  display: none;
}

.overlay--bg {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.overlay--content {
  position: absolute;
  z-index: 201;
  top: 80px;
  left: 50%;
  width: 100%;
  max-width: 740px;
  min-height: 100px;
  min-height: 100px;
  margin-left: -370px;
  background-color: #fff;
}

.overlay--content_in {
  padding: 45px;
}

.overlay--content_title {
  display: block;
  margin-bottom: 15px;
  font-size: 28px;
  font-size: 1.75rem;
}

.overlay--content_text {
  line-height: 1.8;
  margin-bottom: 50px;
}

.overlay--content_close {
  line-height: 29px;
  position: relative;
  display: block;
  width: 90px;
  margin: 0 auto;
  height: 30px;
  text-align: center;
  text-decoration: none;
  color: #000;
  border: 1px solid #000;
  transition: 0.2s;
  font-size: 12px;
  font-size: 0.75rem;
}

.overlay--content_close:before, .overlay--content_close:after {
  position: absolute;
  top: 50%;
  right: 8px;
  display: block;
  width: 13px;
  margin-top: -1px;
  content: '';
  border-bottom: 2px solid #000;
  transition: 0.2s;
}

.overlay--content_close:before {
  transform: rotate(45deg);
}

.overlay--content_close:after {
  transform: rotate(-45deg);
}

.overlay--content_close:hover {
  background-color: #000;
  color: #fff;
}

.overlay--content_close:hover:before, .overlay--content_close:hover:after {
  transform: rotate(0deg);
  border-bottom: 2px solid #fff;
}

@media only screen and (max-width: 600px) {
  .overlay--content {
    margin-left: 0;
    left: 0;
    top: 0;
  }
  .overlay--content_in {
    padding: 10px;
  }
  .overlay--content_title {
    font-size: 18px;
    font-size: 1.125rem;
    margin-bottom: 10px;
  }
  .overlay--content_text {
    margin-bottom: 20px;
  }
}

.sns_block {
  margin: 0 auto -44px auto;
  max-width: 950px;
  position: relative;
  z-index: 10;
  top: 3px;
}

.sns_block--inner {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  max-width: 260px;
  text-align: center;
  margin: 0 0 0 auto;
}

.sns_block #twitter-widget-0 {
  position: relative !important;
  top: 1px;
}

@media only screen and (max-width: 600px) {
  .sns_block--inner {
    margin: 0 auto;
    max-width: 100%;
  }
}

.page_top {
  position: fixed;
  display: block;
  bottom: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.8);
  width: 50px;
  height: 50px;
  transition: .25s;
  z-index: 10;
}

@media screen and (max-width: 767px) {
  .page_top {
    display: none;
  }
}

.page_top:before {
  content: '';
  display: block;
  margin: 5px auto 0;
  width: 15px;
  height: 15px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(-45deg);
  position: absolute;
  top: 15px;
  left: 17px;
  transition: .25s;
}

.page_top:hover {
  transition: .25s;
  background: rgba(0, 0, 0, 0.5);
}

.page_top:hover:before {
  transition: .25s;
  top: 12px;
}

@media only screen and (max-width: 320px) {
  .page_top {
    right: 5px;
    bottom: 5px;
    width: 50px;
    height: 50px;
  }
}

.box {
  background: #fff;
  padding-top: 10px;
}

.box--ttl {
  font-family: "YuGothic", "游ゴシック";
  font-size: 15px;
  font-size: 0.9375rem;
  padding: 0 5px;
}

.box--ttl_name {
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  color: #fff;
  padding: 5px;
  display: inline-block;
  margin-right: 10px;
}

.box--date {
  font-size: 18px;
  font-size: 1.125rem;
  padding: 20px 5px 5px;
  font-weight: bold;
  presentation-level: adjacent;
  white-space: nowrap;
  text-align: center;
}

.box--date-briefing {
  padding-top: 10px;
}

.box--date_number {
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  font-size: 30px;
  font-size: 1.875rem;
}

.box--date_number-briefing {
  font-size: 33px;
  font-size: 2.0625rem;
}

.box--brackets:before {
  content: '（';
  display: inline-block;
  margin-left: -8px;
}

.box--brackets:after {
  content: '）';
  display: inline-block;
}

.box--bottom {
  font-family: "YuGothic", "游ゴシック";
  font-weight: 500;
}

.box--bottom-noapply {
  line-height: 34px;
}

.box--bottom_txt {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  line-height: 1;
  font-size: 14px;
  font-size: 0.875rem;
}

.box--link {
  text-decoration: none;
  display: inline-block;
}

.box--link i {
  font-size: 10px;
  margin-right: 3px;
}

.box--link_apply {
  float: left;
  font-size: 16px;
  font-size: 1rem;
  font-weight: bold;
  background: #fff;
  border-radius: 3px;
  padding: 5px;
  line-height: 1;
  transition: .2s;
}

.box--link_apply:hover {
  color: #fff;
  background: #ff0000;
}

.box--link_apply:hover .box--link_apply_on {
  color: #ff0000;
  background: #fff;
  transition: .2s;
}

.box--link_apply_on {
  font-size: 14px;
  font-size: 0.875rem;
  margin-left: 5px;
  background: #ff0000;
  color: #fff;
  border-radius: 3px;
  padding: 5px;
  display: inline-block;
  transition: .2s;
}

.box--link_pdf {
  float: right;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fff;
  background: #0052a1;
  border-radius: 3px;
  padding: 1px 10px 10px;
  line-height: 1;
  transition: .2s;
}

.box--link_pdf i {
  font-size: 22px;
  margin-right: 0;
  margin-left: 3px;
  position: relative;
  top: 4px;
}

.box--link_pdf:hover {
  color: #0052a1;
  background: #fff;
  transition: .2s;
}

.course_info {
  background: #333;
  padding-top: 12px;
  padding-bottom: 12px;
}

.course_info--inner {
  width: 950px;
  margin: 0 auto;
}

.course_info--wrap {
  width: auto;
  margin: 0 auto;
}

.course_info--wrap:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '.';
}

.course_info .box {
  float: left;
}

.course_info .box-session {
  width: 164px;
}

.course_info .box-session .box--ttl {
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
}

.course_info .box-session .box--ttl span {
  font-size: 12px;
  font-size: 0.75rem;
  display: inline-block;
  color: #cc0000;
  margin-bottom: 5px;
}

.course_info .box-session .box--ttl img {
  margin-bottom: 5px;
}

.course_info .box-session .box--bottom {
  width: 100%;
  background: #0052a1;
  text-align: center;
}

.course_info .box-session .box--link {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  display: block;
  padding: 8px 0;
}

.course_info .box-session .box--link span {
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: normal;
  letter-spacing: -0.5px;
  white-space: nowrap;
}

.course_info .box-session .box--link:hover {
  text-decoration: underline;
}

.course_info .box-search, .course_info .box-break {
  width: 383px;
  margin-left: 10px;
  padding-bottom: 44px;
}

.course_info .box-search {
  padding-top: 15px;
  position: relative;
}

.course_info .box-search .box--ttl {
  padding-left: 10px;
  padding-right: 10px;
  color: #51be80;
  font-size: 14px;
  font-size: 0.875rem;
}

.course_info .box-search .box--ttl_name {
  background: #51be80;
}

.course_info .box-search .box--bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #51be80;
  padding: 5px;
}

.course_info .box-search .box--bottom:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '.';
}

.course_info .box-search .box--link_apply {
  color: #51be80;
}

.course_info .box-search .box--link_apply:hover {
  color: #fff;
}

.course_info .box-break {
  padding-top: 15px;
  position: relative;
}

.course_info .box-break .box--ttl {
  padding-left: 10px;
  padding-right: 10px;
  color: #fbb03b;
}

.course_info .box-break .box--ttl_name {
  background: #fbb03b;
}

.course_info .box-break .box--bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #fbb03b;
  padding: 5px;
}

.course_info .box-break .box--bottom:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '.';
}

.course_info .box-break .box--link_apply {
  color: #fbb03b;
}

.course_info .box-break .box--link_apply:hover {
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .course_info .box {
    float: none;
    width: 100%;
  }
  .course_info .box--date {
    font-size: 16px;
    font-size: 1rem;
  }
  .course_info .box--date_number {
    font-size: 22px;
    font-size: 1.375rem;
  }
  .course_info .box--link_apply {
    font-size: 13px;
    font-size: 0.8125rem;
    width: 66%;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
  }
  .course_info .box--link_apply_on {
    font-size: 12px;
    font-size: 0.75rem;
    padding-left: 3px;
    padding-right: 3px;
    margin-left: 2px;
  }
  .course_info .box--link_pdf {
    font-size: 12px;
    font-size: 0.75rem;
    width: 32%;
    text-align: center;
    padding-top: 10px;
  }
  .course_info .box--link_pdf i {
    display: none;
  }
  .course_info .box-session:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: '.';
  }
  .course_info .box-session .box--ttl {
    margin-bottom: 10px;
    width: 100%;
  }
  .course_info .box-session .box--ttl img {
    width: 45%;
    margin-bottom: 0;
  }
  .course_info .box-session .box--date {
    width: 40%;
    float: left;
  }
  .course_info .box-session .box--bottom {
    width: 50%;
    float: right;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 3px;
  }
  .course_info .box-session .box--link {
    vertical-align: middle;
  }
  .course_info .box-session .box--link span {
    white-space: normal;
  }
  .course_info .box-search {
    margin-left: 0;
    margin-top: 10px;
    padding-top: 13px;
    padding-bottom: 0;
  }
  .course_info .box-search .box--ttl {
    line-height: 1.3;
  }
  .course_info .box-search .box--ttl:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: '.';
  }
  .course_info .box-search .box--ttl span {
    float: left;
    position: relative;
    top: -4px;
    line-height: 1;
  }
  .course_info .box-search .box--bottom {
    position: relative;
  }
  .course_info .box-break {
    margin-left: 0;
    margin-top: 10px;
    padding-top: 13px;
    padding-bottom: 0;
  }
  .course_info .box-break .box--ttl {
    line-height: 1.3;
  }
  .course_info .box-break .box--ttl:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: '.';
  }
  .course_info .box-break .box--ttl span {
    float: left;
    position: relative;
    top: -4px;
    line-height: 1;
  }
  .course_info .box-break .box--bottom {
    position: relative;
  }
  .course_info .box-break .box--bottom_txt {
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 1;
    font-size: 12px;
    font-size: 0.75rem;
  }
}

@media only screen and (max-width: 600px) {
  .course_info {
    padding-left: 10px;
    padding-right: 10px;
  }
  .course_info--inner {
    width: 100%;
  }
}
