@charset 'UTF-8';

.gl-header {
  width: 100%;
  padding: 15px;
  background: #fff;
  border-bottom: 5px solid $color-blue;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  @include sp {
    height: 70px;
    padding: 15px 15px 15px 10px;
  }
  &--inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  &--logo {
    display: block;
    position: relative;
    @include sp {
      margin-left: 0;
      width: 244px;
      top: -4px;
    }
  }
  &--logo_img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  &--hamburger {
    display: none;
    @include sp {
      background-color: $color-main;
      width: 65px;
      position: absolute;
      right: 0;
      top: 0;
      color: #fff;
      font-size: 10px;
      height: 65px;
      text-align: center;
      display: block;
    }
    &.active {
      overflow: hidden;
      .gl-header {
        &--hamburger_border {
          &:nth-child(1) {
            transform: rotate(45deg);
            top: 24px;
          }
          &:nth-child(2) {
            left: -100%;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
            top: 24px;
          }
        }
      }
    }
  }
  &--hamburger_border {
    border-bottom: 2px solid #fff;
    position: absolute;
    height: 2px;
    width: 30px;
    left: calc(50% - 15px);
    display: block;
    transition: 0.15s;
    &:nth-child(1) {
      top: 15px;
    }
    &:nth-child(2) {
      top: 24px;
    }
    &:nth-child(3) {
      top: 33px;
    }
  }
  &--hamburger_txt {
    width: 100%;
    position: absolute;
    bottom: 12px;
    left: 0;
  }
}

.gl-header_nav {
  display: flex;
  flex-wrap: wrap;
  max-width: 460px;
  position: relative;
  padding: 0 120px 0 0;
  margin: 0 0 0 auto;
  z-index: 1;
  @include sp {
    max-width: 100%;
    height: auto;
    background-color: rgba(255,255,255,0.8);
    min-height: 100vh;
    position: absolute;
    left: 0;
    top: 65px;
    padding: 25px 15px;
    display: none;
    width: 100%;
    opacity: 0;
    transition: 0.15s;
    z-index: 10;
  }
  &.show {
    opacity: 1;
    .gl-header_nav {
      &--link {
        opacity: 1;
        top: 0;
        @for $i from 1 through 4 {
          &:nth-child(#{$i}){
            transition: 0.2s;
            transition-delay: $i *0.03s;
          }
        }
      }
      &--btn {
        opacity: 1;
        top: 0;
        transition: 0.2s;
        transition-delay: 4 *0.03s;
      }
    }
  }
  &--link {
    display: block;
    text-decoration: none;
    color: #000;
    font-size: 12px;
    width: 50%;
    position: relative;
    padding: 0 0 0 20px;
    line-height: 15px;
    height: 15px;
    transition: 0.15s;
    &:before {
      content: "";
      width: 15px;
      height: 15px;
      background-color: $color-main;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
    }
    &:after {
      content: '';
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      width: 4px;
      height: 4px;
      display: block;
      position: absolute;
      left: 4px;
      top: 5px;
      transform: rotate(-135deg);
    }
    &:hover {
      color: $color-main;
    }
    &:nth-child(1), &:nth-child(2) {
      margin-bottom: 10px;
    }

    @include sp {
      width: 100%;
      background-color: $color-main;
      color: #fff;
      height: 43px;
      line-height: 43px;
      font-size: 14px;
      margin: 0 0 10px 0;
      padding: 0 0 0 30px;
      opacity: 0;
      top: 5px;
      &:after {
        width: 6px;
        height: 6px;
        border-left-width: 2px;
        border-bottom-width: 2px;
        top: calc(50% - 6px);
        left: 10px;
      }
      &:hover {
        color: #fff;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &-margin_bottom {
        margin-bottom: 30px;
      }
    }

  }
  &--btn {
    background-color: $color-main;
    color: #fff;
    font-weight: bold;
    width: 120px;
    height: 40px;
    line-height: 40px;
    border-radius: 50px;
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.15s;
    &:hover {
      background-color: darken($color-main,10%);
    }
    &-pdf {
      display: none;
    }
    @include sp {
      display: block;
      position: static;
      width: 100%;
      margin-bottom: 10px;
      border-radius: 21px;
      background-color: #008da8;
      opacity: 0;
      top: 5px;
      &:last-child {
        margin-bottom: 0;
      }
      &-pdf {
        display: flex;
        justify-content: center;
        align-items: center;
        &:after {
          content: '';
          background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2215%22%3E%3Cpath%20data-name%3D%22Icon%20material-insert-drive-file%22%20d%3D%22M1.5%200A1.5%201.5%200%2000.008%201.5L0%2013.5A1.5%201.5%200%20001.492%2015H10.5a1.5%201.5%200%20001.5-1.5v-9L7.5%200zm5.25%205.25V1.125l4.125%204.125z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E');
          display: block;
          width: 12px;
          height: 15px;
          margin-left: 6px;
        }
      }
    }
  }
}
