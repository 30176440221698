/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$sprite-banners-anchour-name: 'sprite-banners-anchour';
$sprite-banners-anchour-x: 160px;
$sprite-banners-anchour-y: 0px;
$sprite-banners-anchour-offset-x: -160px;
$sprite-banners-anchour-offset-y: 0px;
$sprite-banners-anchour-width: 60px;
$sprite-banners-anchour-height: 60px;
$sprite-banners-anchour-total-width: 220px;
$sprite-banners-anchour-total-height: 140px;
$sprite-banners-anchour-image: 'src/common/images/';
$sprite-banners-anchour: (160px, 0px, -160px, 0px, 60px, 60px, 220px, 140px, 'src/common/images/', 'sprite-banners-anchour', );
$sprite-banners-yellow-anchour-name: 'sprite-banners-yellow_anchour';
$sprite-banners-yellow-anchour-x: 60px;
$sprite-banners-yellow-anchour-y: 56px;
$sprite-banners-yellow-anchour-offset-x: -60px;
$sprite-banners-yellow-anchour-offset-y: -56px;
$sprite-banners-yellow-anchour-width: 60px;
$sprite-banners-yellow-anchour-height: 60px;
$sprite-banners-yellow-anchour-total-width: 220px;
$sprite-banners-yellow-anchour-total-height: 140px;
$sprite-banners-yellow-anchour-image: 'src/common/images/';
$sprite-banners-yellow-anchour: (60px, 56px, -60px, -56px, 60px, 60px, 220px, 140px, 'src/common/images/', 'sprite-banners-yellow_anchour', );
$sprite-blink-name: 'sprite-blink';
$sprite-blink-x: 120px;
$sprite-blink-y: 86px;
$sprite-blink-offset-x: -120px;
$sprite-blink-offset-y: -86px;
$sprite-blink-width: 24px;
$sprite-blink-height: 18px;
$sprite-blink-total-width: 220px;
$sprite-blink-total-height: 140px;
$sprite-blink-image: 'src/common/images/';
$sprite-blink: (120px, 86px, -120px, -86px, 24px, 18px, 220px, 140px, 'src/common/images/', 'sprite-blink', );
$sprite-c-secondary-button-anchour-name: 'sprite-c_secondary_button-anchour';
$sprite-c-secondary-button-anchour-x: 160px;
$sprite-c-secondary-button-anchour-y: 60px;
$sprite-c-secondary-button-anchour-offset-x: -160px;
$sprite-c-secondary-button-anchour-offset-y: -60px;
$sprite-c-secondary-button-anchour-width: 40px;
$sprite-c-secondary-button-anchour-height: 40px;
$sprite-c-secondary-button-anchour-total-width: 220px;
$sprite-c-secondary-button-anchour-total-height: 140px;
$sprite-c-secondary-button-anchour-image: 'src/common/images/';
$sprite-c-secondary-button-anchour: (160px, 60px, -160px, -60px, 40px, 40px, 220px, 140px, 'src/common/images/', 'sprite-c_secondary_button-anchour', );
$sprite-features-list-anchour-name: 'sprite-features_list-anchour';
$sprite-features-list-anchour-x: 0px;
$sprite-features-list-anchour-y: 0px;
$sprite-features-list-anchour-offset-x: 0px;
$sprite-features-list-anchour-offset-y: 0px;
$sprite-features-list-anchour-width: 160px;
$sprite-features-list-anchour-height: 56px;
$sprite-features-list-anchour-total-width: 220px;
$sprite-features-list-anchour-total-height: 140px;
$sprite-features-list-anchour-image: 'src/common/images/';
$sprite-features-list-anchour: (0px, 0px, 0px, 0px, 160px, 56px, 220px, 140px, 'src/common/images/', 'sprite-features_list-anchour', );
$sprite-pack-list-item-anchour-name: 'sprite-pack-list_item-anchour';
$sprite-pack-list-item-anchour-x: 0px;
$sprite-pack-list-item-anchour-y: 56px;
$sprite-pack-list-item-anchour-offset-x: 0px;
$sprite-pack-list-item-anchour-offset-y: -56px;
$sprite-pack-list-item-anchour-width: 60px;
$sprite-pack-list-item-anchour-height: 60px;
$sprite-pack-list-item-anchour-total-width: 220px;
$sprite-pack-list-item-anchour-total-height: 140px;
$sprite-pack-list-item-anchour-image: 'src/common/images/';
$sprite-pack-list-item-anchour: (0px, 56px, 0px, -56px, 60px, 60px, 220px, 140px, 'src/common/images/', 'sprite-pack-list_item-anchour', );
$sprite-primary-button-anchour-name: 'sprite-primary_button-anchour';
$sprite-primary-button-anchour-x: 200px;
$sprite-primary-button-anchour-y: 60px;
$sprite-primary-button-anchour-offset-x: -200px;
$sprite-primary-button-anchour-offset-y: -60px;
$sprite-primary-button-anchour-width: 14px;
$sprite-primary-button-anchour-height: 24px;
$sprite-primary-button-anchour-total-width: 220px;
$sprite-primary-button-anchour-total-height: 140px;
$sprite-primary-button-anchour-image: 'src/common/images/';
$sprite-primary-button-anchour: (200px, 60px, -200px, -60px, 14px, 24px, 220px, 140px, 'src/common/images/', 'sprite-primary_button-anchour', );
$sprite-primary-button-anchour-on-name: 'sprite-primary_button-anchour_on';
$sprite-primary-button-anchour-on-x: 0px;
$sprite-primary-button-anchour-on-y: 116px;
$sprite-primary-button-anchour-on-offset-x: 0px;
$sprite-primary-button-anchour-on-offset-y: -116px;
$sprite-primary-button-anchour-on-width: 14px;
$sprite-primary-button-anchour-on-height: 24px;
$sprite-primary-button-anchour-on-total-width: 220px;
$sprite-primary-button-anchour-on-total-height: 140px;
$sprite-primary-button-anchour-on-image: 'src/common/images/';
$sprite-primary-button-anchour-on: (0px, 116px, 0px, -116px, 14px, 24px, 220px, 140px, 'src/common/images/', 'sprite-primary_button-anchour_on', );
$sprite-primary-button-green-anchour-on-name: 'sprite-primary_button-green_anchour_on';
$sprite-primary-button-green-anchour-on-x: 28px;
$sprite-primary-button-green-anchour-on-y: 116px;
$sprite-primary-button-green-anchour-on-offset-x: -28px;
$sprite-primary-button-green-anchour-on-offset-y: -116px;
$sprite-primary-button-green-anchour-on-width: 12px;
$sprite-primary-button-green-anchour-on-height: 24px;
$sprite-primary-button-green-anchour-on-total-width: 220px;
$sprite-primary-button-green-anchour-on-total-height: 140px;
$sprite-primary-button-green-anchour-on-image: 'src/common/images/';
$sprite-primary-button-green-anchour-on: (28px, 116px, -28px, -116px, 12px, 24px, 220px, 140px, 'src/common/images/', 'sprite-primary_button-green_anchour_on', );
$sprite-primary-button-yellow-anchour-on-name: 'sprite-primary_button-yellow_anchour_on';
$sprite-primary-button-yellow-anchour-on-x: 14px;
$sprite-primary-button-yellow-anchour-on-y: 116px;
$sprite-primary-button-yellow-anchour-on-offset-x: -14px;
$sprite-primary-button-yellow-anchour-on-offset-y: -116px;
$sprite-primary-button-yellow-anchour-on-width: 14px;
$sprite-primary-button-yellow-anchour-on-height: 24px;
$sprite-primary-button-yellow-anchour-on-total-width: 220px;
$sprite-primary-button-yellow-anchour-on-total-height: 140px;
$sprite-primary-button-yellow-anchour-on-image: 'src/common/images/';
$sprite-primary-button-yellow-anchour-on: (14px, 116px, -14px, -116px, 14px, 24px, 220px, 140px, 'src/common/images/', 'sprite-primary_button-yellow_anchour_on', );
$sprite-round-anchour-name: 'sprite-round-anchour';
$sprite-round-anchour-x: 120px;
$sprite-round-anchour-y: 56px;
$sprite-round-anchour-offset-x: -120px;
$sprite-round-anchour-offset-y: -56px;
$sprite-round-anchour-width: 30px;
$sprite-round-anchour-height: 30px;
$sprite-round-anchour-total-width: 220px;
$sprite-round-anchour-total-height: 140px;
$sprite-round-anchour-image: 'src/common/images/';
$sprite-round-anchour: (120px, 56px, -120px, -56px, 30px, 30px, 220px, 140px, 'src/common/images/', 'sprite-round-anchour', );
$spritesheet-width: 220px;
$spritesheet-height: 140px;
$spritesheet-image: 'src/common/images/';
$spritesheet-sprites: ($sprite-banners-anchour, $sprite-banners-yellow-anchour, $sprite-blink, $sprite-c-secondary-button-anchour, $sprite-features-list-anchour, $sprite-pack-list-item-anchour, $sprite-primary-button-anchour, $sprite-primary-button-anchour-on, $sprite-primary-button-green-anchour-on, $sprite-primary-button-yellow-anchour-on, $sprite-round-anchour, );
$spritesheet: (220px, 140px, 'src/common/images/', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
