@charset 'UTF-8';
@import 'sprite';
@import 'mixin';
@import 'common/gl-header';
@import 'common/gl-footer';
.c-briefing {
  color: #fff;
  text-decoration: none;
  position: fixed;
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  z-index: 2;
  &:hover {
    background-color: #000;
    border-color: #000;
  }
  &-pc {
    border: 2px solid $color-blue;
    top: 104px;
    right: calc(50% - 490px);
    max-width: 300px;
    background-color: $color-blue;
    height: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    transition: background-color .15s, top .2s, left .2s;
    @include sp {
      display: none;
    }
  }
  &-personal {
    max-width: 300px;
  }
  &-sp {
    display: none;
    @include sp {
      display: block;
      position: fixed;
      width: 100%;
      padding: 10px;
      font-size: 18px;
      text-align: center;
      margin-bottom: 0;
      background-color: #28b5c1;
      border: 0;
      top: initial;
      left: 0;
      bottom: 0;
    }
    &:before {
      @include sp {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        top: calc(50% - 8px);
        right: 20px;
      }
    }
    &:after {
      @include sp {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-right: 2px solid $color-main;
        border-bottom: 2px solid $color-main;
        transform: rotate(-45deg);
        position: absolute;
        top: calc(50% - 3px);
        right: 26px;
      }
    }
    &:hover {
      @include sp {
        background-color: darken($color-main, 10%);
      }
    }
  }
  &--txt {
    text-align: center;
    flex: 1;
    position: relative;
    padding: 0 18px 0 0;
    &:before {
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      right: 10px;
      top: calc(50% - 7px);
    }
    &:after {
      content: '';
      display: block;
      border-left: 1px solid $color-blue;
      border-bottom: 1px solid $color-blue;
      transform: rotate(-135deg);
      position: absolute;
      right: 16px;
      top: calc(50% - 2px);
      width: 4px;
      height: 4px;
    }
    @include sp {
      text-align: left;
      font-size: 14px;
      line-height: 1.5;
      padding: 0 0 0 10px;
      width: 100%;
      display: block;
      box-sizing: border-box;
      flex: inherit;
    }
  }
  &--day {
    background-color: #fff;
    color: #000;
    height: 100%;
    display: block;
    padding: 0 10px;
    line-height: 36px;
    @include sp {
      flex: 1;
      font-size: 13px;
      line-height: 45px;
      padding: 0 10px;
      display: none;
    }
  }
  &.fixed {
    @include sp {
      width: 70%;
      position: fixed;
      top: initial;
      left: 15px;
      bottom: 25px;
      box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
      margin-bottom: 0;
    }
  }
  &--micro {
    @include sp {
      font-size: 13px;
      display: block;
      margin-bottom: 3px;
    }
  }
}
.c-course {
  max-width: 332px;
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  line-height: 1;
  text-decoration: none;
  border-bottom: 5px solid transparent;
  position: relative;
  transition: 0.15s;
  @include sp {
    max-width: 100%;
    margin-bottom: 10px;
    &:hover {
      transform: scale(1, 1);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &-green {
    left: 0;
    border-bottom-color: $color-green;
    &:hover {
      border-bottom-color: darken($color-green, 10%);
      .c-course {
        &--ttl {
          background-color: darken($color-green, 10%);
          &:before {
            border-color: darken($color-green, 10%) transparent transparent transparent;
          }
        }
        &--edge {
          background-color: darken($color-green, 10%);
          &:before {
            border-color: transparent transparent darken($color-green, 10%) transparent;
          }
        }
      }
    }
    .c-course {
      &--ttl {
        background-color: $color-green;
        &:before {
          border-top-color: $color-green;
        }
      }
      &--catch {
        color: $color-green;
      }
      &--txt {
        color: $color-green;
      }
      &--edge {
        background-color: $color-green;
        &:before {
          border-bottom-color: $color-green;
        }
      }
      &--arrow {
        &:before {
          border-bottom-color: $color-green;
          border-left-color: $color-green;
        }
      }
    }
  }
  &-orange {
    right: 0;
    border-bottom-color: $color-orange;
    &:hover {
      border-bottom-color: darken($color-orange, 10%);
      .c-course {
        &--ttl {
          background-color: darken($color-orange, 10%);
          &:before {
            border-color: darken($color-orange, 10%) transparent transparent transparent;
          }
        }
        &--edge {
          background-color: darken($color-orange, 10%);
          &:before {
            border-color: transparent transparent darken($color-orange, 10%) transparent;
          }
        }
      }
    }
    .c-course {
      &--ttl {
        background-color: $color-orange;
        &:before {
          border-top-color: $color-orange;
        }
      }
      &--catch {
        color: $color-orange;
      }
      &--txt {
        color: $color-orange;
      }
      &--edge {
        background-color: $color-orange;
        &:before {
          border-bottom-color: $color-orange;
        }
      }
      &--arrow {
        &:before {
          border-bottom-color: $color-orange;
          border-left-color: $color-orange;
        }
      }
    }
  }
  &--ttl {
    background-color: red;
    color: #fff;
    position: relative;
    height: 28px;
    line-height: 28px;
    width: 130px;
    text-align: center;
    display: block;
    margin: 0 0 10px 0;
    transition: 0.15s;
    &:before {
      content: "";
      display: block;
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 28px 20px 0 0;
      border-color: red transparent transparent transparent;
      transition: 0.15s;
    }
    @include sp {
      margin-bottom: 8px;
    }
  }
  &--catch {
    font-style: italic;
    font-weight: bold;
    position: absolute;
    left: 150px;
    top: 8px;
    font-size: 12px;
  }
  &--day {
    font-weight: bold;
    color: #000;
    font-size: 22px;
    text-align: center;
    margin: 0 0 15px 0;
    @include sp {
      text-align: left;
      font-size: 14px;
      padding: 0 10px;
      letter-spacing: -1px;
      margin-bottom: 6px;
    }
  }
  &--txt {
    font-size: 16px;
    text-align: right;
    padding: 0 45px 8px 0;
    display: block;
    font-weight: bold;
    @include sp {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 12px;
    }
  }
  &--edge {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
    background-color: red;
    transition: 0.15s;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -12px;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 30px 12px;
      border-color: transparent transparent red transparent;
      transition: 0.15s;
    }
    @include sp {
      height: 25px;
      width: 25px;
      &:before {
        border-width: 0 0 25px 10px;
        left: -10px;
      }
    }
  }
  &--arrow {
    background-color: #fff;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    right: 9px;
    top: 9px;
    display: block;
    &:before {
      content: '';
      display: block;
      border-left: 1px solid #00528c;
      border-bottom: 1px solid #00528c;
      -ms-transform: rotate(-135deg);
      transform: rotate(-135deg);
      position: absolute;
      top: 5px;
      right: 6px;
      width: 4px;
      height: 4px;
    }
    @include sp {
      right: 6px;
      top: 6px;
    }
  }
}
// global navigation
.gl_navi {
  float: right;
  padding: 13px 130px 0 0;
  list-style: none;
  width: 520px;
  position: relative;
  @include fz(14);
  &--item {
    position: relative;
    top: 12px;
    float: left;
    margin-right: 10px;
    margin: 0 0 7px 10px;
    width: 180px;
    &_link {
      transition: .15s;
      text-decoration: none;
      color: #000;
      &:before {
        position: relative;
        top: 2px;
        display: inline-block;
        margin-right: 5px;
        content: '';
        @include sprite($sprite-round-anchour);
      }
      &:hover {
        opacity: .8;
      }
    }
  }
  &--contact {
    position: absolute;
    right: 0;
    top: 8px;
    .gl_navi--item_link {
      display: inline-block;
      width: 100px;
      padding: 5px 0;
      line-height: 1.4;
      position: relative;
      width: 130px;
      top: 4px;
      transition: .15s;
      text-align: center;
      color: #fff;
      border-radius: 3px;
      background-color: #008da8;
      @include fz(12);
      &:before {
        display: none;
      }
      &:hover {
        background-color: $color-main;
      }
    }
  }
  &--big {
    @include fz(14);
    display: block;
    margin: 2px 0 0 0;
  }
  @include media-tb {
    position: absolute;
    top: 50px;
    left: 0;
    display: none;
    width: 100%;
    padding-top: 10px;
    padding-right: 0;
    padding-left: 0;
    &--big {
      display: inline-block;
    }
    &--item {
      top: 0;
      float: none;
      width: 100%;
      margin-bottom: 10px;
      padding: 0 10px;
      margin-left: 0;
      &_link {
        line-height: 43px;
        display: block;
        float: none;
        width: 100%;
        height: 43px;
        text-align: center;
        color: #fff;
        border-radius: 3px;
        background-color: $color-main;
      }
    }
    &--contact {
      float: none;
      margin-bottom: 15px;
      margin-top: 20px;
      height: auto;
      top: 0;
      width: 100%;
      margin-left: 0;
      padding: 0 10px 15px;
      position: static;
      .gl_navi--item_link {
        display: block;
        height: auto;
        width: 100%;
        top: 0;
        padding: 10px 0;
      }
    }
  }
}
// global navigationのハンバーガーボタン
.humburger {
  position: absolute;
  top: 50%;
  right: 10px;
  display: none;
  width: 27px;
  height: 25px;
  margin-top: -10px;
  @include media-tb {
    display: block;
    &--bg {
      display: none;
    }
  }
  &--line {
    display: block;
    width: 100%;
    margin-bottom: 6px;
    margin-left: auto;
    transition: .3s;
    border-bottom: 4px solid $color-main;
    &:nth-child(2) {
      width: 60%;
    }
    &:last-child {
      width: 80%;
    }
  }
  &--bg {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: block;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .9);
  }
  &-on {
    .humburger--line {
      position: relative;
      &:first-child {
        top: 7px;
        transform: rotate(-45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:last-child {
        top: -13px;
        width: 100%;
        transform: rotate(45deg);
      }
    }
  }
}
// global footer
.footer {
  height: 57px;
  margin: 0 auto;
  padding: 20px 0;
  background-color: #e6e6e6;
  @include fz(12);
  @include clearfix;
  &--inner {
    max-width: 950px;
    margin: 0 auto;
  }
  &--pp {
    display: block;
    float: left;
    width: 300px;
    text-decoration: none;
    color: #000;
    transition: 0.15s;
    position: relative;
    &:hover {
      color: $color-main;
    }
    &:before {
      content: '';
      display: inline-block;
      @include sprite($sprite-round-anchour);
      position: relative;
      left: 0;
      top: 3px;
      margin-right: 5px;
    }
  }
  &--copyright {
    float: right;
    vertical-align: middle;
    text-align: right;
    position: relative;
    top: -3px;
    &_text {
      @include fz(10);
      display: block;
      margin-top: 5px;
    }
  }
  &--link {
    color: $color-main;
  }
  @include media-tb {
    height: auto;
    padding-bottom: 20px;
    text-align: center;
    @include fz(11);
    &--pp {
      float: none;
      width: 100%;
      margin-bottom: 10px;
      margin-left: 0;
    }
    &--copyright {
      float: none;
      width: 100%;
      &_text {
        line-height: 1.6;
        padding: 0 10px;
        text-align: center;
      }
    }
  }
}
// お申込み・お問い合わせはこちら
.contact {
  padding: 50px 0;
  background-color: $color-main;
  &--button {
    font-weight: bold;
    position: relative;
    z-index: 10;
    display: block;
    width: 100%;
    max-width: 575px;
    margin: 0 auto;
    padding: 20px 0 15px;
    transition: .2s;
    text-align: center;
    text-decoration: none;
    color: $color-main;
    box-shadow: 4px 4px 0 #008da8;
    @include fz(26);
    &:before {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      transition: .2s;
      background-color: #fff;
    }
    &:hover {
      box-shadow: 0 0 3px #008da8;
      &:before {
        background-color: #008da8;
      }
    }
  }
  @include media-tb {
    padding: 20px 10px;
    &--button {
      padding: 15px 15px;
      @include fz(16);
    }
  }
}
// access
.c-access {
  padding-top: 30px;
  &--ttl {
    color: #01669C;
    font-size: 30px;
    line-height: 1.5;
    text-align: center;
    margin: 0 0 40px 0;
    @include sp {
      font-size: 20px;
      margin-bottom: 20px;
      line-height: 1.3;
    }
  }
}
.c-access_box {
  display: flex;
  @include sp {
    flex-wrap: wrap;
  }
  &--detail {
    width: 500px;
    padding: 20px 20px 40px 0;
    margin-left: calc(50vw - 500px);
    @include sp {
      width: 100%;
      margin-left: 0;
      order: 1;
      padding: 20px 15px;
    }
  }
  &--wrap {
    margin: 0 0 16px;
    &:after {
      content: '';
      width: 94%;
      height: 1px;
      background: #01669B;
      display: block;
      margin: 16px 0 0 16px;
    }
  }
  &--center_logo {
    @include sp {
      display: block;
      margin-bottom: 5px;
    }
  }
  &--tel {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 42px;
    display: block;
    margin: 0 0 20px;
    cursor: default;
    text-decoration: none;
    color: #000;
    @include sp {
      font-size: 35px;
    }
  }
  &--link {
    font-size: 14px;
    display: inline-block;
    color: $color-main;
    margin: 2px 0 5px 3px;
  }
  &--txt {
    font-size: 14px;
    line-height: 1.7;
    margin-bottom: 5px;
  }
  &--note {
    font-size: 12px;
    line-height: 1.5;
  }
  &--map {
    width: 50vw;
    @include sp {
      width: 100%;
      order: 0;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
//2020.05~プログラムの実施場所変更に伴い、追加
.c-access_program {
  border-left: 5px solid $color-main;
  padding: 0 0 0 16px;
  margin: 0 0 0 14px;
  &--wrap {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 0 0 10px;
  }
  &--ttl {
    font-size: 22px;
    font-weight: $fw-bold;
    color: $color-blue;
    margin: 0 8px 0 0;
    @include sp {
      font-size: 16px;
    }
    > sup {
      font-size: 60%;
      position: relative;
      top: -5px;
    }
  }
  &--link {
    font-size: 12px;
    display: block;
    background: #DCDCDC;
    border-radius: 10px;
    width: 57px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    transition: .2s;
    &:hover {
      background: #999;
    }
  }
  &--text {
    line-height: 1.7;
  }
  &--note {
    font-size: 14px;
    @include sp {
      font-size: 12px;
    }
  }
}
// interview
.c-interview {
  padding-top: 40px;
  padding-bottom: 40px;
  @include sp {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  &--ttl {
    font-size: 30px;
    font-weight: $fw-bold;
    text-align: center;
    margin-bottom: 30px;
    @include sp {
      font-size: 20px;
      margin-bottom: 20px;
      line-height: 1.3;
    }
  }
}
.c-interview_list {
  max-width: 780px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include sp {
    padding-left: 15px;
    padding-right: 15px;
  }
  &--item {
    &:nth-child(n+3) {
      margin: 60px 0 0;
    }
    @include sp {
      &:nth-child(n+2) {
        margin: 30px 0 0;
      }
    }
  }
  &--link {
    width: 360px;
    display: block;
    @include sp {
      width: 100%;
    }
    &:hover {
      .interview_pagination--button-narrow {
        background: #3aa3ae;
        border: solid 2px #3AA3AE;
        transition: 0.15s;
        color: #ffffff;
      }
    }
  }
  &--pic {
    display: block;
    img {
      display: block;
    }
  }
  &--name {
    font-size: 24px;
    text-align: center;
    display: block;
    margin-top: 10px;
    margin-bottom: 16px;
    @include sp {
      font-size: 16px;
    }
    &-interview {
      color: #3aa3ae;
      font-weight: bold;
    }
  }
  &--btn {
    width: 175px;
    font-size: 14px;
    font-weight: $fw-medium;
    text-align: center;
    color: #fff;
    background: $color-main;
    margin: 0 auto;
    display: block;
    padding: 8px;
    position: relative;
    transition: background-color 0.15s;
    @include sp {
      width: 200px;
    }
    &:after {
      content: '';
      width: 6px;
      height: 6px;
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
      transform: rotate(-45deg);
      display: block;
      position: absolute;
      top: calc(50% - 4px);
      right: 10px
    }
  }
  &--detail {
    margin-bottom: 24px;
    @include sp {
      margin-top: 15px;
      margin-bottom: 20px;
    }
  }
  &--detail_item {
    margin-bottom: 8px;
    font-size: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    @include sp {
      margin-bottom: 8px;
    }
  }
  &--detail_span {
    position: relative;
    background: #3aa3ae;
    color: #fff;
    width: 70px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    margin-right: 16px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    &:after {
      content: "";
      position: absolute;
      right: -10px;
      top: 0;
      border-right: 0px solid transparent;
      border-left: 10px solid #3aa3ae;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
    }
  }
  &--color {
    font-weight: bold;
    &-orange {
      color: #e18935;
    }
    &-green {
      color: #399F6E;
    }
  }
}
// support
.c-support_briefing {
  max-width: 980px;
  margin: 40px auto;
  border: 2px solid $color-blue;
  border-radius: 20px;
  padding: 35px 50px;
  @include sp {
    margin: 30px 15px;
    padding: 20px 15px;
    border-radius: 10px;
  }
  &--ttl {
    color: $color-blue;
    text-align: center;
    margin: 0 0 20px 0;
    font-size: 30px;
    @include sp {
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 10px;
    }
  }
  &--attention {
    text-align: center;
    margin: 20px 0 0;
    font-size: 14px;
    font-weight: $fw-bold;
    @include sp {
      font-size: 12px;
      text-align: left;
      margin: 10px 0 0;
    }
  }
  &--description {
    text-align: center;
    margin: 0 0 30px 0;
    line-height: 1.8;
    font-size: 16px;
    @include sp {
      font-size: 14px;
      text-align: left;
      margin-bottom: 15px;
      br {
        display: none;
      }
    }
  }
  &--content {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin: 0 0 50px 0;
    justify-content: space-between;
    align-items: flex-end;
    @include sp {
      justify-content: center;
      flex-direction: column;
      position: relative;
      margin-bottom: 30px;
    }
  }
  &--headline {
    font-size: 18px;
    line-height: 1.5;
    padding: 0 0 0 55px;
    display: block;
    position: relative;
    &:before {
      content: '';
      display: block;
      background: url('/common/images/c-support-briefing-icon.png') no-repeat;
      background-size: cover;
      width: 41px;
      height: 40px;
      position: absolute;
      left: 0;
      top: calc(50% - 20px);
    }
    @include sp {
      text-align: left;
      width: 200px;
      font-size: 14px;
      display: block;
      margin: 15px auto 0;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  &--other {
    @include sp {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 14px;
    }
  }
  &--btn {
    color: #fff;
    text-decoration: none;
    background-color: $color-blue;
    font-size: 22px;
    max-width: 560px;
    width: 100%;
    padding: 32px 0;
    display: block;
    margin: 0 auto;
    text-align: center;
    transition: 0.15s;
    &:hover {
      background-color: darken($color-blue, 10%);
    }
    @include sp {
      width: 100%;
      font-size: 16px;
      line-height: 1.5;
      padding: 10px 0;
      border-radius: 3px;
    }
  }
}
// course,interview
.c-ft_contents {
  background: $color-main;
  padding: 40px 0;
  @include sp {
    padding: 20px 15px;
  }
}
.c-ft_course {
  max-width: 910px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.c-ft_course_box {
  width: 434px;
  border: 2px solid #fff;
  @include sp {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &:hover {
    .c-ft_course_box--heading {
      width: 165px;
      @include sp {
        width: 146px;
      }
      &:after {
      }
    }
  }
  &-explore {
    .c-ft_course_box {
      &--description {
        &:before {
          background: $color-green;
        }
      }
      &--heading {
        background: $color-green;
        &:after {
          border-color: $color-green transparent transparent transparent;
        }
      }
      &--free {
        color: $color-green;
      }
      &--txt {
        color: $color-green;
      }
    }
  }
  &-break {
    .c-ft_course_box {
      &--description {
        &:before {
          background: $color-orange;
        }
      }
      &--heading {
        background: $color-orange;
        &:after {
          border-color: $color-orange transparent transparent transparent;
        }
      }
      &--free {
        color: $color-orange;
      }
      &--txt {
        color: $color-orange;
      }
    }
  }
  &-break {
  }
  &--pic {
    img {
      display: block;
    }
  }
  &--description {
    display: flex;
    background: #fff;
    align-items: center;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 10px);
      right: 12px;
      @include sp {
        width: 14px;
        height: 14px;
        top: calc(50% - 7px);
        right: 4px;
      }
    }
    &:after {
      content: '';
      display: block;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      transform: rotate(-135deg);
      position: absolute;
      top: calc(50% - 4px);
      right: 19px;
      width: 6px;
      height: 6px;
      @include sp {
        width: 3px;
        height: 3px;
        top: calc(50% - 2px);
        right: 9px;
      }
    }
  }
  &--heading {
    width: 170px;
    font-size: 14px;
    font-weight: $fw-bold;
    line-height: 1.5;
    color: #fff;
    padding: 5px 0 5px 10px;
    position: relative;
    transition: .2s;
    @include sp {
      width: 146px;
      font-size: 12px;
    }
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 52px 25px 0 0;
      position: absolute;
      top: 0;
      right: -25px;
      @include sp {
        border-width: 46px 15px 0 0;
        right: -15px
      }
    }
  }
  &--free {
    font-size: 13px;
    font-weight: $fw-bold;
    color: $color-green;
    display: inline-block;
    padding: 3px 5px;
    background: #fff;
    border-radius: 8px;
    line-height: 1;
    @include sp {
      font-size: 12px;
    }
  }
  &--txt {
    font-size: 14px;
    font-weight: $fw-bold;
    line-height: 1.5;
    padding-left: 40px;
    @include sp {
      font-size: 12px;
      padding-left: 20px;
    }
  }
}
.c-ft_interview_box {
  max-width: 910px;
  margin: 40px auto 0;
  display: flex;
  border: 2px solid #fff;
  @include sp {
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  &:hover {
    .c-ft_interview_box--heading {
      width: 120px;
    }
  }
  &--pic {
    width: 466px;
    @include sp {
      width: 100%;
    }
    img {
      display: block;
    }
  }
  &--description {
    width: calc(100% - 466px);
    display: flex;
    background: #fff;
    position: relative;
    @include sp {
      width: 100%;
    }
    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $color-main;
      position: absolute;
      top: calc(50% - 10px);
      right: 20px;
    }
    &:after {
      content: '';
      display: block;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      transform: rotate(-135deg);
      position: absolute;
      top: calc(50% - 4px);
      right: 27px;
      width: 6px;
      height: 6px;
    }
  }
  &--heading {
    width: 100px;
    font-size: 20px;
    font-weight: $fw-bold;
    color: #fff;
    background: $color-main;
    display: flex;
    align-items: center;
    position: relative;
    transition: .15s;
    @include sp {
      width: 120px;
      font-size: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
    }
    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 120px 45px 0 0;
      border-color: $color-main transparent transparent transparent;
      position: absolute;
      top: 0;
      right: -45px;
      @include sp {
        border-width: 44px 20px 0 0;
        right: -20px;
      }
    }
  }
  &--txt {
    font-size: 20px;
    font-weight: $fw-bold;
    color: $color-main;
    padding-left: 30px;
    display: flex;
    align-items: center;
    @include sp {
      font-size: 16px;
      padding-left: 30px;
    }
  }
}
// 体験者インタビュー
.interview_list {
  &--item {
    position: relative;
    &_num {
      position: absolute;
      top: 6px;
      left: -24px;
      &-corp {
        &:before {
          border-bottom-color: #008da8;
        }
      }
    }
    &_pic {
      margin-bottom: 15px;
    }
    &_name {
      display: block;
      margin-bottom: 15px;
      text-align: center;
      @include fz(16);
      line-height: 1.6;
      height: 2.1em;
    }
    &_button {
      display: block;
      width: 175px;
      margin: 0 auto;
    }
  }
  @include media-tb {
    &--item {
      &_num {
        top: 2px;
        left: -15px;
      }
      &_pic {
        margin-bottom: 5px;
      }
      &_name {
        @include fz(12);
      }
      &_button {
        width: 100%;
        &:after {
          display: none;
        }
      }
    }
  }
}
// オーバーレイ
.overlay {
  display: none;
  &--bg {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
  }
  &--content {
    position: absolute;
    z-index: 201;
    top: 80px;
    left: 50%;
    width: 100%;
    max-width: 740px;
    min-height: 100px;
    min-height: 100px;
    margin-left: -(740px/2);
    background-color: #fff;
    &_in {
      padding: 45px;
    }
    &_title {
      display: block;
      margin-bottom: 15px;
      @include fz(28);
    }
    &_text {
      line-height: 1.8;
      margin-bottom: 50px;
    }
    &_close {
      line-height: 29px;
      position: relative;
      display: block;
      width: 90px;
      margin: 0 auto;
      height: 30px;
      text-align: center;
      text-decoration: none;
      color: #000;
      border: 1px solid #000;
      transition: 0.2s;
      @include fz(12);
      &:before,
      &:after {
        position: absolute;
        top: 50%;
        right: 8px;
        display: block;
        width: 13px;
        margin-top: -1px;
        content: '';
        border-bottom: 2px solid #000;
        transition: 0.2s;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
      &:hover {
        background-color: #000;
        color: #fff;
        &:before, &:after {
          transform: rotate(0deg);
          border-bottom: 2px solid #fff;
        }
      }
    }
  }
  @include media-tb {
    &--content {
      margin-left: 0;
      left: 0;
      top: 0;
      &_in {
        padding: 10px;
      }
      &_title {
        @include fz(18);
        margin-bottom: 10px;
      }
      &_text {
        margin-bottom: 20px;
      }
    }
  }
}
// SNS
.sns_block {
  margin: 0 auto -44px auto;
  max-width: 950px;
  position: relative;
  z-index: 10;
  top: 3px;
  &--inner {
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    max-width: 260px;
    text-align: center;
    margin: 0 0 0 auto;
  }
  #twitter-widget-0 {
    position: relative !important;
    top: 1px;
  }
  @include media-tb {
    &--inner {
      margin: 0 auto;
      max-width: 100%;
    }
  }
}
//Page top
.page_top {
  position: fixed;
  display: block;
  bottom: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.8);
  width: 50px;
  height: 50px;
  transition: .25s;
  z-index: 10;
  @include sp {
    display: none;
  }
  &:before {
    content: '';
    display: block;
    margin: 5px auto 0;
    width: 15px;
    height: 15px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg);
    position: absolute;
    top: 15px;
    left: 17px;
    transition: .25s;
  }
  &:hover {
    transition: .25s;
    background: rgba(0, 0, 0, 0.5);
    &:before {
      transition: .25s;
      top: 12px;
    }
  }
}
@include media-sp {
  .page_top {
    right: 5px;
    bottom: 5px;
    width: 50px;
    height: 50px;
  }
}
// course information
.box {
  background: #fff;
  padding-top: 10px;
  &--ttl {
    font-family: "YuGothic", "游ゴシック";
    @include fz(15);
    padding: 0 5px;
  }
  &--ttl_name {
    font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
    color: #fff;
    padding: 5px;
    display: inline-block;
    margin-right: 10px;
  }
  &--date {
    @include fz(18);
    padding: 20px 5px 5px;
    font-weight: bold;
    presentation-level: adjacent;
    white-space: nowrap;
    text-align: center;
    &-briefing {
      padding-top: 10px;
    }
  }
  &--date_number {
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    @include fz(30);
    &-briefing {
      @include fz(33);
    }
  }
  &--brackets {
    &:before {
      content: '（';
      display: inline-block;
      margin-left: -8px;
    }
    &:after {
      content: '）';
      display: inline-block;
    }
  }
  &--bottom {
    font-family: "YuGothic", "游ゴシック";
    font-weight: 500;
    &-noapply {
      line-height: 34px;
    }
  }
  &--bottom_txt {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    line-height: 1;
    @include fz(14);
  }
  &--link {
    text-decoration: none;
    display: inline-block;
    i {
      font-size: 10px;
      margin-right: 3px;
    }
  }
  &--link_apply {
    float: left;
    @include fz(16);
    font-weight: bold;
    background: #fff;
    border-radius: 3px;
    padding: 5px;
    line-height: 1;
    transition: .2s;
    &:hover {
      color: #fff;
      background: #ff0000;
      .box--link_apply_on {
        color: #ff0000;
        background: #fff;
        transition: .2s;
      }
    }
  }
  &--link_apply_on {
    @include fz(14);
    margin-left: 5px;
    background: #ff0000;
    color: #fff;
    border-radius: 3px;
    padding: 5px;
    display: inline-block;
    transition: .2s;
  }
  &--link_pdf {
    float: right;
    @include fz(14);
    font-weight: bold;
    color: #fff;
    background: #0052a1;
    border-radius: 3px;
    padding: 1px 10px 10px;
    line-height: 1;
    transition: .2s;
    i {
      font-size: 22px;
      margin-right: 0;
      margin-left: 3px;
      position: relative;
      top: 4px;
    }
    &:hover {
      color: #0052a1;
      background: #fff;
      transition: .2s;
    }
  }
  @include media-sp {
  }
}
.course_info {
  background: #333;
  padding-top: 12px;
  padding-bottom: 12px;
  &--inner {
    width: 950px;
    margin: 0 auto;
  }
  &--wrap {
    width: auto;
    margin: 0 auto;
    @include clearfix;
  }
  .box {
    float: left;
    &-session {
      width: 164px;
      .box {
        &--ttl {
          font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
          span {
            @include fz(12);
            display: inline-block;
            color: #cc0000;
            margin-bottom: 5px;
          }
          img {
            margin-bottom: 5px;
          }
        }
        &--bottom {
          width: 100%;
          background: #0052a1;
          text-align: center;
        }
        &--link {
          color: #fff;
          text-decoration: none;
          @include fz(14);
          font-weight: bold;
          display: block;
          padding: 8px 0;
          span {
            @include fz(10);
            font-weight: normal;
            letter-spacing: -0.5px;
            white-space: nowrap;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    &-search, &-break {
      width: 383px;
      margin-left: 10px;
      padding-bottom: 44px;
    }
    &-search {
      padding-top: 15px;
      position: relative;
      .box {
        &--ttl {
          padding-left: 10px;
          padding-right: 10px;
          color: #51be80;
          @include fz(14);
        }
        &--ttl_name {
          background: #51be80;
        }
        &--bottom {
          width: 100%;
          position: absolute;
          bottom: 0;
          background: #51be80;
          padding: 5px;
          @include clearfix;
        }
        &--link {
        }
        &--link_apply {
          color: #51be80;
          &:hover {
            color: #fff;
          }
        }
      }
    }
    &-break {
      padding-top: 15px;
      position: relative;
      .box {
        &--ttl {
          padding-left: 10px;
          padding-right: 10px;
          color: #fbb03b;
        }
        &--ttl_name {
          background: #fbb03b;
        }
        &--bottom {
          width: 100%;
          position: absolute;
          bottom: 0;
          background: #fbb03b;
          padding: 5px;
          @include clearfix;
        }
        &--link {
        }
        &--link_apply {
          color: #fbb03b;
          &:hover {
            color: #fff;
          }
        }
      }
    }
    @include media-tb {
      float: none;
      width: 100%;
      &--date {
        @include fz(16);
      }
      &--date_number {
        @include fz(22);
      }
      &--link {
      }
      &--link_apply {
        @include fz(13);
        width: 66%;
        padding-left: 2px;
        padding-right: 2px;
        text-align: center;
      }
      &--link_apply_on {
        @include fz(12);
        padding-left: 3px;
        padding-right: 3px;
        margin-left: 2px;
      }
      &--link_pdf {
        @include fz(12);
        width: 32%;
        text-align: center;
        padding-top: 10px;
        i {
          display: none;
        }
      }
      &-session {
        @include clearfix;
        .box {
          &--ttl {
            margin-bottom: 10px;
            width: 100%;
            img {
              width: 45%;
              margin-bottom: 0;
            }
          }
          &--date {
            width: 40%;
            float: left;
          }
          &--bottom {
            width: 50%;
            float: right;
            margin-right: 5px;
            margin-bottom: 5px;
            border-radius: 3px;
          }
          &--link {
            vertical-align: middle;
            span {
              white-space: normal;
            }
          }
        }
      }
      &-search {
        margin-left: 0;
        margin-top: 10px;
        padding-top: 13px;
        padding-bottom: 0;
        .box {
          &--ttl {
            @include clearfix;
            line-height: 1.3;
            span {
              float: left;
              position: relative;
              top: -4px;
              line-height: 1;
            }
          }
          &--bottom {
            position: relative;
          }
        }
      }
      &-break {
        margin-left: 0;
        margin-top: 10px;
        padding-top: 13px;
        padding-bottom: 0;
        .box {
          &--ttl {
            @include clearfix;
            line-height: 1.3;
            span {
              float: left;
              position: relative;
              top: -4px;
              line-height: 1;
            }
          }
          &--bottom {
            position: relative;
          }
          &--bottom_txt {
            padding-top: 5px;
            padding-bottom: 5px;
            line-height: 1;
            @include fz(12);
          }
        }
      }
    }
  }
  @include media-tb {
    padding-left: 10px;
    padding-right: 10px;
    &--inner {
      width: 100%;
    }
  }
}








